export const GOOGLE_MAPS_API_KEY = 'AIzaSyCnTho_AsCzLwD6w6bc0LH6ZsWZRofJGB8';
export const APPINSIGHTS_CONNECTION_STRING = 'InstrumentationKey=dcde0e9f-bdf6-49da-80b8-97896150efc7;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/';
export const HERE_API_KEY = 'y9mYK-Ne85i5EgOl4xpvNGwWFtN88dL7FkCorquQ6ZU';
export const FUNCTION_APP_URI = '/api/';
export const GTAG_TRACKING_ID = 'UA-542400-176';
export const B2C_CLIENT_ID = 'd6ce7ab1-8299-4950-bc25-fadbdee3bf74';
export const B2C_TENANT_NAME = '7601e553-beb8-4f63-a5ef-a7ba22a4ac28';
export const B2C_AUTHORITY = 'https://KnightFrankB2Cdevtest.b2clogin.com/KnightFrankB2Cdevtest.onmicrosoft.com/B2C_1_iHealthcare_SignIn';
export const B2C_KNOWN_AUTHORITIES = 'KnightFrankB2Cdevtest.b2clogin.com';
export const B2C_SCOPES = [
  'https://KnightFrankB2Cdevtest.onmicrosoft.com/3298f727-42bf-45a1-aa3c-1e0d8fef5622/user_impersonation'
];
export const B2C_SIGNIN = 'B2C_1_iHealthcare_SignIn';